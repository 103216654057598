/**
 * @param baseUrl e.g. `/workflows` or `/settings`
 */
export function createEpmConfigRoutes(baseUrl: string) {
	return {
		default: {
			key: 'epm-config',
			path: `${baseUrl}/epm-config`
		},
		setup: {
			key: 'setup',
			path: `${baseUrl}/epm-config/setup`
		},
		forwarding_profiles: {
			key: 'fwd-profiles',
			path: `${baseUrl}/epm-config/forwarding-profiles/profile`
		},
		forwarding_profiles_create: {
			key: 'fwd-profiles_create',
			path: `${baseUrl}/epm-config/forwarding-profiles/profile/create`
		},
		forwarding_profiles_edit: {
			key: 'fwd-profiles_edit',
			path: `${baseUrl}/epm-config/forwarding-profiles/profile/edit`
		},

		forwarding_profiles_source: {
			key: 'fwd-profiles-source',
			path: `${baseUrl}/epm-config/forwarding-profiles/source`
		},
		source_application_create: {
			key: 'source_application_create',
			path: `${baseUrl}/epm-config/forwarding-profiles/source/create`
		},
		source_application_edit: {
			key: 'source_application_edit',
			path: `${baseUrl}/epm-config/forwarding-profiles/source/edit`
		},
		forwarding_profiles_destination: {
			key: 'fwd-profiles-destination',
			path: `${baseUrl}/epm-config/forwarding-profiles/destination`
		},
		destination_create: {
			key: 'destination_create',
			path: `${baseUrl}/epm-config/forwarding-profiles/destination/create`
		},
		destination_edit: {
			key: 'destination_edit',
			path: `${baseUrl}/epm-config/forwarding-profiles/destination/edit`
		},
		forwarding_profiles_connectivity: {
			key: 'fwd-profiles-connectivity',
			path: `${baseUrl}/epm-config/forwarding-profiles/connectivity`
		},
		connectivity_create: {
			key: 'connectivity_create',
			path: `${baseUrl}/epm-config/forwarding-profiles/connectivity/create`
		},
		connectivity_edit: {
			key: 'connectivity_edit',
			path: `${baseUrl}/epm-config/forwarding-profiles/connectivity/edit`
		},
		setup_infrastructure: {
			key: 'infrastructure',
			path: `${baseUrl}/epm-config/setup/infra-setting`
		},
		setup_infrastructure_edit: {
			key: 'infrastructure-edit',
			path: `${baseUrl}/epm-config/infrastructure/edit`
		},
		ssaa_global_app_settings: {
			key: 'global_app_settings',
			path: `${baseUrl}/epm-config/global_app_settings`
		},
		staged_rollout: {
			key: 'staged_rollout',
			path: `${baseUrl}/epm-config/staged_rollout/ring_detail`
		},
		agent_settings_edit: {
			key: 'agent-settings_edit',
			path: `${baseUrl}/epm-config/setup/agent/settings/edit`
		},
		agent_settings_create: {
			key: 'agent-settings_create',
			path: `${baseUrl}/epm-config/setup/agent/settings/create`
		},
		agent_config: {
			key: 'agent-config',
			path: `${baseUrl}/epm-config/setup/agent`
		},
		user_authentication_display: {
			key: 'user_authentication',
			path: `${baseUrl}/epm-config/user_authentication`
		},
		snapshots: {
			key: 'snapshots',
			path: `${baseUrl}/epm-config/snapshots`
		}
	} as const
}
