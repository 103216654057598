import type { FeatureInfo } from '@sparky/framework'

export function isFeatureEnabled(featureInfo: FeatureInfo[] | undefined, featureKey: string) {
	return !!featureInfo?.some(f => f.name === featureKey && f.toggle === 1)
}

export function getEpmConfigRootKey(isPanoramaTenant: boolean) {
	return isPanoramaTenant ? 'settings' : 'workflows'
}

export function isEpmConfigAvail() {
	return false
}
